<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCar/parking" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <!-- 入住办理功能 -->
  <vxe-modal ref="xModal" width="800" title="入住办理" show-zoom>
    <template v-slot>
      <vxe-form ref="xForm" v-bind="stayInOptions" v-loading="loading" @submit="submitEvent" @reset="closeModal"></vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      selectRow: null,
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let { type, communityId, id } = this.currNode;
                  if (type == "2001") {
                    let { data } = this.formOptions;
                    data["parentId"] = id;
                    data["communityId"] = communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error("请选择停车场");
                  }
                },
              },
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "车位编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                maxlength: 24,
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "chargingPoint",
            title: "是否有充电桩",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否有充电桩",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                maxlength: 24,
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "area",
            title: "车位面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入车位面积(平方米)",
              },
            },
          },
          {
            field: "ownerName",
            title: this.$t("currentOwner"),
            minWidth: 120,
            editRender: {
              name: "$input",
              enabled: false,
              props: {
                placeholder: this.$t("currentPlaceholder"),
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              enabled: false,
              props: {
                placeholder: "请输入联系电话",
              }
            },
          },
          {
            field: "status",
            title: "车位状态",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "入住办理",
                  name: 'stayIn',
                  events: {
                    click: (row) => {
                      this.queryEvent(row)
                    }
                  }
                },
                {
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent"
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent"
                }
              ]
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              props: {
                placeholder: "请选择所属上级",
              },
              expandAll: true,
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '2001') {
                    data["communityId"] = node.communityId;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择停车场')
                  }
                },
              },
            },
          },
          {
            field: "name",
            title: "车位编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                maxlength: 24,
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "area",
            title: "车位面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                max: 100,
                maxlength: 24,
                placeholder: "请输入车位面积(平方米)",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                maxlength: 24,
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "chargingPoint",
            title: "是否有充电桩",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否有充电桩",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                placeholder: "请输入排序号",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          name: [{ required: true, message: "请输入车位编号" }],
          parkingSpaceType: [{ required: true, message: "请选择车位类型" }],
          chargingPoint: [{ required: true, message: "请选择是否有充电桩" }],
          parkingSpacePayType: [{ required: true, message: "请选择缴费类型" }],
          orderCode: [{ required: true, message: "请输入排序号" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          name: "",
          type: "2002",
          parkingSpaceType: "1",
          chargingPoint: "0",
          parkingSpacePayType: "1",
          enabled: "1",
          orderCode: "0"
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "车位编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "status",
            title: "车位状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          parkingSpaceType: "",
          parkingSpacePayType: "",
          status: "",
          enabled: "",
        },
      },

      stayInOptions: {
        // 新增功能字段配置
        width: 800,
        height: 600,
        titleWidth: 100,
        items: [{
            field: "ownerName",
            title: this.$t("currentOwner"),
            span: 12,
            itemRender: {
              name: "$VxeSelectOwner",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                suffixIcon: "fa fa-search",
                placeholder: this.$t("currentPlaceholder"),
              },
              events: {
                click: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["ownerId"] = row.id;
                  data["ownerLink"] = row.phone;
                  data["status"] = row.id ? "1" : "0";
                },
              },
            },
          },
          {
            field: "ownerLink",
            title: "联系方式",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "status",
            title: "车位状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            field: "checkInDate",
            title: "入住日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择入住日期",
              },
            },
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          ownerName: [{ required: true, message: this.$t("currentPlaceholder") }],
          ownerLink: [{ required: true, message: "请输入联系方式" }],
          status: [{ required: true, message: "请选择车位状态" }],
          checkInDate: [{ required: true, message: "请选择入住日期" }],
        },
        // 新增功能字段初始化
        data: {
          ownerId: "",
          status: "1",
          checkInDate: "",
        },
      },
    };
  },

  methods: {
    ...mapActions([
      "queryEventService",
      "submitEventService",
    ]),
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      let { data } = this.searchOptions;
      data["parentType"] = node.type;
      data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },
    // 查询房屋信息
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
        service: this.$route.meta.service,
        params: row,
        dictTypeCodes: ["parkStatus"]
      }).then(({ code, data, dicts }) => {
        if (code == 200) {
          this.stayInOptions.data = data;
          this.$Tools.buildDataByDicts(this.stayInOptions.items, dicts, { value: "code", label: "name" });
          this.$refs.xModal.open();
        }
      });
    },

    // 入住办理
    submitEvent({ data }) {
      this.submitEventService({
        service: this.$route.meta.service,
        params: data,
      }).then(({ code }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
          this.$message.success(data.id ? "更新成功" : "保存成功");
          this.closeModal();
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },
    resetEvent() {
      this.loading = false;
    },
    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
